<template>
  <div
    class="app flex-row align-items-center"
    style="
      backgroundimage: url('/img/brand/bg_main1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    "
  >
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="5">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form>
                  <b-img
                    center
                    src="/img/brand/baslik_ortali.png"
                    width="125px;"
                    alt="Logo"
                  ></b-img>
                  <br />
                  <h1>{{ $t("login") }}</h1>
                  <p class="text-muted">{{ $t("loginSubtitle") }}</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-user"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="text"
                      class="form-control"
                      :placeholder="$t('usernameOrEmail')"
                      autocomplete="username email"
                      v-model="emailOrUserName"
                      @keyup.enter="onEnter"
                    />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-lock"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="password"
                      class="form-control"
                      :placeholder="$t('password')"
                      autocomplete="current-password"
                      v-model="password"
                      @keyup.enter="onEnter"
                    />
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button @click="login" variant="primary" class="px-4">{{
                        $t("login")
                      }}</b-button>
                    </b-col>
                    <b-col cols="6" class="text-right">
                      <b-button
                        variant="link"
                        @click="primaryModal = true"
                        class="px-0"
                        >{{ $t("forgottenPassword") }}</b-button
                      >
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
    <b-modal
      :cancel-title="$t('cancel')"
      :ok-title="$t('send')"
      hide-footer
      :title="$t('forgottenPassword')"
      size="sm"
      class="modal-primary"
      v-model="primaryModal"
    >
      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-input-group-text>
            <i class="icon-user"></i>
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          type="text"
          class="form-control"
          :placeholder="$t('usernameOrEmail')"
          autocomplete="email"
          v-model="forgottenPasswordEmailOrUsername"
          aria-required="true"
        />
      </b-input-group>

      <b-col cols="6" style="margin-top: 25px; padding: 0">
        <b-button @click="onSubmit" variant="primary" class="px-4">{{
          $t("send")
        }}</b-button>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import http from "../../shared/http";
import toast from "../../shared/toast-helper";
import jwtHelper from "../../shared/jwt-helper";
export default {
  name: "Login",
  data() {
    return {
      primaryModal: false,
      emailOrUserName: "",
      password: "",
      forgottenPasswordEmailOrUsername: "",
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      this.$router.push({ path: "/dashboard" });
    }
  },
  methods: {
    onEnter: function () {
      this.login();
    },
    onSubmit() {
      if (
        !this.forgottenPasswordEmailOrUsername ||
        this.forgottenPasswordEmailOrUsername.length < 5
      ) {
        this.primaryModal = true;
        toast.warning(this.$t("emptyUserNamePassword"));
      } else {
        let path = `auth/createandsendpasswordlink?userNameOrEmail=${this.forgottenPasswordEmailOrUsername}`;
        http.get(path).then((data) => {
          if (data) {
            toast.success(this.$t("passwordSentToMail"));
            this.primaryModal = false;
          }
        });
      }
    },
    login() {
      if (this.emailOrUserName.length < 5 || this.password.length < 5) {
        toast.warning(this.$t("emptyUserNamePassword"));
        return;
      }
      let path = "auth/login";
      let data = {
        emailOrUserName: this.emailOrUserName,
        password: this.password,
      };
      http.post(path, data).then((result) => {
        if (result) {
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("expiration", result.data.expiration);
          if (jwtHelper.getIsEmployee() == 0) {
            toast.error(this.$t("employeeLoginError"));
            http.logout();
          } else {
            this.$router.push({ path: "/dashboard" });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.language-section {
  position: fixed;
  top: 10px;
  right: 50px;
}
</style>